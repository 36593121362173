import { extendObservable, action, computed } from 'mobx';
import TrackRequest from '../utils/TrackRequest';

class BuildingsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.trackRequest = new TrackRequest();

    extendObservable(this, {
      items: [],
      /************************************************
       * Computed Properties (selectors)
       *************************************************/

      hasBuildings: computed(() => {
        return !!this.items.length;
      }),

      hasMoreThanOneBuilding: computed(() => {
        return this.items.length > 1;
      }),

      selectedBuilding: computed(() => {
        const { buildingId } = this.rootStore.ui;
        // TODO: If no selected building, return the campuses default building (provided by WWF settings)
        return (this.items || []).find(building => building.id === buildingId);
      }),

      buildingById: buildingId => {
        return (this.items || []).find(building => building.id === buildingId);
      },

      /************************************************
       * Actions
       *************************************************/

      fetchBuildings: action('fetchBuildings', ({ campusId, token } = {}) => {
        this.trackRequest.track(
          this.rootStore
            .api('/buildings', {
              params: { campusId },
              token: token || this.rootStore.settings.token,
              label: 'buildings',
            })
            .then(
              action('fetchBuildingsSuccess', res => {
                this.items.replace(res.data.items);
              })
            )
        );
      }),
    });
  }
}

export default BuildingsStore;
