import {
    extendObservable,
    action,
    observable
} from 'mobx';
import TrackRequest from '../utils/TrackRequest';
import request from 'request';
import { API_HOST } from '../config';

const observableImage = (url, image) => {
    return observable({
        url,
        base64: image
    });
};

const blobFromArray = (typedArray, mimeType) => {
    return new Blob([typedArray.buffer], {type: mimeType});
};

class ImageCacheStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.trackRequest = new TrackRequest();

        extendObservable(this, {
            // The Poi types
            items: [],
            invalidated: 0,
            downloaded: {},
            getImage: url => {
                let result = this.items.find(image => image.url === url);
                if(!result){
                    this.fetchImage(url);
                    return;
                }
                return result ? result.base64 : null;
            },

            invalidate: action('invalidate', () => {
                this.invalidated += 1;// = true;
            }),
            fetchImage: action(
                'fetchImage',
                (url) => {
                    if (this.downloaded[url]) {
                        return;
                    }
                    this.downloaded[url] = true;
                    this.trackRequest.track(fetch(`${API_HOST}/resource/poi`,{ 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${this.rootStore.settings.token}`
                        }, 
                        body: JSON.stringify({url: url})
                    }).then(res => res.text()).then(action('fetchImageSuccess', image =>{
                                let newItems = this.items.toJS();
                                newItems.push(observableImage(url, image));
                                this.items.replace(newItems);
                        })
                    ));
                }
            )
        })
    }

    bootstrap() {
        return this.dispose.bind(this);
    }

    dispose() {
    }
}

export default ImageCacheStore;
