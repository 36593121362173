import { API_HOST } from '../config';
import alertEmitter from '../utils/alertEmitter';
import qs from 'qs';

export const handleApiErrors = res => {
  if (!res.ok) {
    throw new Error(res.statusText);
  } else {
    return res;
  }
};

const api = (
  path,
  {
    params = null,
    label,
    token,
    body,
    method,
    _fetch = global.fetch,
    _Headers = global.Headers,
    _alertEmitter = alertEmitter,
    _handleApiErrors = handleApiErrors,
  }
) => {
  const options = {};
  if (token) {
    options.headers = new _Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
  }
  if (body) {
    options.body = JSON.stringify(body);
    options.method = method;
    options.headers = new _Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
  }

  const query = params ? `?${qs.stringify(params)}` : '';
  return _fetch(`${API_HOST}${path}${query}`, options)
    .then(_handleApiErrors)
    .then(res => res.json())
    .catch(err => {
      //console.log(err);
      if (!__PROD__ && !__TEST__) {
        console.error(`${err} - when fetching ${label}`); // eslint-disable-line no-console
      }
      _alertEmitter.show(`There was an error fetching the ${label}.`, 'danger');
    });
};

// Fetch paginated requests so all entities are loaded.
// An optional `progress` handler can be passed as the second argument to get
// progress updates as pages are loaded: `(items: array, data: object) => void`.
// The data param will have properties "offset", "resultCount", "totalCount".
// NOTE: The `progress` is not guaranteed to be called in page order. Use
// `data.offset` to track the page order if needed.
api.allEntities = async (
  path,
  { params = null, label, token, progress = () => {}, _api = api, limit = 100 }
) => {
  // Fetch a page of entities adding the offset and limit to the query params.
  const fetchPage = offset => {
    const pageParams = {
      ...(params || {}),
      offset,
      limit,
    };

    return _api(path, {
      params: pageParams,
      label: `${label}-offset-${offset}`,
      token,
    }).then(res => {
      // Call the progress handler with the results of this page.
      progress((res && res.data && res.data.items) || [], res.data);

      return res.data;
    });
  };

  // Fetch the first page of entities.
  const firstPage = await fetchPage(0);

  // If there are no more entities, then we don't need to fetch anymore pages.
  if (
    firstPage.resultCount === 0 ||
    firstPage.offset + firstPage.resultCount >= firstPage.totalCount
  ) {
    return firstPage.items;
  }

  // Queue up the rest of the page fetches so they run in parallel.
  const followUpPageFetches = [];
  for (
    let offset = firstPage.items.length;
    offset < firstPage.totalCount;
    offset += limit
  ) {
    followUpPageFetches.push(fetchPage(offset));
  }

  // Wait for all pages to laod and then return one array with all page results.
  const pageResults = await Promise.all(followUpPageFetches);
  return pageResults.reduce((entities, page) => {
    if (!page) {
      return entities;
    }

    return entities.concat(page.items);
  }, firstPage.items);
};

export default api;
