import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Logo from '@phunware/ui/lib/Logo';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  margin-top: 20vh;
  text-align: center;
`;

const ErrorPage = ({ code, subtitle, message }) => {
  return (
    <Container>
      <Content>
        <Logo color="#323232" />
        { code > 0 && (<h1>{code}</h1>) }
        { code > 0 ? (<h4>{subtitle}</h4>) : (<h3>{subtitle}</h3>) }
        <p>{message}</p>
      </Content>
    </Container>
  );
};

ErrorPage.propTypes = {
  code: PropTypes.number,
  subtitle: PropTypes.string,
  message: PropTypes.string,
};
ErrorPage.defaultProps = {
  code: 404,
  subtitle: 'Page not found',
  message: 'The page you are looking for could not be found.',
};

export default ErrorPage;
