let API_HOST = '/api';

if (__LOCAL__) {
  API_HOST = 'http://localhost:3001/api';
}
export { API_HOST };

export const PHONE_SHARE_API_ROUTE = '/routes/directions/share/phone';

export const EMAIL_USERNAME = 'hostmaster@phunware.com';
export const EMAIL_API = 'https://api.elasticemail.com';
export const EMAIL_KEY = 'b7c90e67-0931-4ba4-81b9-783fa95f8984';
export const EMAIL_FROM = 'no-reply@phunware.com';

export const DEFAULT_LOGO = require('./components/Header/pw_logo.png');
export const DEFAULT_DARK_LOGO = require('./components/Header/pw_logo_dark.png');
export const DEFAULT_MAP_ZOOM = 20;
export const DEFAULT_POI_ICON_SIZE = 32;
export const SMALL_POI_ICON_SIZE = 20;
export const DEFAULT_POI_ICON =
  'https://lbs-prod.s3.amazonaws.com/stock_assets/icons/99999.png';

export const MAP_ROUTE_COLOR = '#a61111';
export const MAP_ROUTE_STOKE_SIZE = 5;
export const SMALL_MAP_ROUTE_STOKE_SIZE = 2;
export const MAP_ROUTE_STOKE_OPACITY = 1;

export const FONTS = {
  OpenSans: 'Open Sans',
  SourceSansPro: 'Source Sans Pro',
  Roboto: 'Roboto',
};

export const FONT_SIZES = {
  SMALL: 12,
  MEDIUM: 14,
  LARGE: 16,
};

let TEMP_VENUE_ID = 'd521040b-2694-4acd-812f-70db48750107';
if (__STAGE__) {
  // Temporary - to unblock QA's testing.
  TEMP_VENUE_ID = 'bd4ead4d-8b54-40e2-ad8a-d1b2c4bcc43e';
}
export { TEMP_VENUE_ID };
