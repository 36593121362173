import {
    extendObservable,
    action,
    computed,
    observable
} from 'mobx';
import uniqBy from 'lodash/uniqBy';
import TrackRequest from '../utils/TrackRequest';

class CategoriesStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.trackRequest = new TrackRequest();

        extendObservable(this, {
            // The Poi types
            items: [],
            hasCategories: computed(() => {
                return !!this.items.length;
            }),

            fetchCategories: action(
                'fetchCategories',
                ({ token } = {}) => {
                    this.items.replace([]);
                    this.trackRequest.track(
                        this.rootStore.api('/poi-types', {
                                // Filter out the falsey values.
                                label: 'categories',
                                token: token || this.rootStore.settings.token,
                            })
                            .then( res => {
                                if(res && res.data){
                                    this._mergeCategories(res.data.data);
                                }
                            })
                    );
                }
            ),

            clearCategoryList: action('clearCategoryList', () => {
                this.items.replace([]);
            }),
        });
    }

    bootstrap() {
        return this.dispose.bind(this);
    }

    dispose() {
    }

    _mergeCategories(categories) {
        if(!categories)
        {
            return;
        }
        this.items.replace(
            uniqBy(
                this.items
                    .toJS()
                    .concat(categories.map(c => observable( {...c }))),
                'id'
            )
        );
    }
}

export default CategoriesStore;
