import { extendObservable, action, computed, observe } from 'mobx';
import get from 'lodash/get';
import TrackRequest from '../utils/TrackRequest';

const floorToOverlay = floor => {
  return {
    // A floor can have only one set of resources. Ignore the rest of the array.
    imgUrl: get(floor, 'resources[0]', {}).svgUrl,
    bounds: {
      swCorner: {
        lat: get(floor, 'referencePoints.portal.bottomLeft.latitude'),
        lng: get(floor, 'referencePoints.portal.bottomLeft.longitude'),
      },
      neCorner: {
        lat: get(floor, 'referencePoints.portal.topRight.latitude'),
        lng: get(floor, 'referencePoints.portal.topRight.longitude'),
      },
    },
    rotation: get(floor, 'referencePoints.rotation'),
  };
};

class FloorsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.trackRequest = new TrackRequest();

    extendObservable(this, {
      items: [],

      /************************************************
       * Computed Properties (selectors)
       *************************************************/

      hasFloors: computed(() => {
        return !!this.items.length;
      }),

      selectedFloor: computed(() => {
        const { floorId } = this.rootStore.ui;
        return (this.items || []).find(floor => floor.id === floorId);
      }),

      floorById: floorId => {
        return (this.items || []).find(floor => floor.id === floorId);
      },

      selectedFloorOverlay: computed(() => {
        const floor = this.selectedFloor || {};

        return floorToOverlay(floor);
      }),

      // TODO: This should take into account the default floor from the settings. Yet to be
      // implemented by the server team.
      defaultFloor: computed(() => {
        const { buildingId } = this.rootStore.ui;
        return (this.items || []).find(floor => {
          return floor._isDefault && floor.buildingId === buildingId;
        });
      }),

      /************************************************
       * Actions
       *************************************************/

      fetchFloors: action('fetchFloors', ({ buildingId, token } = {}) => {
        this.trackRequest.track(
          this.rootStore
            .api('/floors', {
              params: { buildingId },
              token: token || this.rootStore.settings.token,
              label: 'floors',
            })
            .then(
              action('fetchFloorsSuccess', res => {
                // Set the first floor as the default floor.
                // TODO: This should take into account the WWF settings.
                const floors = res.data.items.map((floor, i) => {
                  return Object.assign({}, floor, { _isDefault: i === 0 });
                });
                this.items.replace(floors);

                if (this.rootStore.ui.floorId) {
                  this.fetchResources({
                    floorId: this.rootStore.ui.floorId,
                  });
                }
              })
            )
        );
      }),

      fetchResources: action('fetchResources', ({ floorId, token } = {}) => {
        this.trackRequest.track(
          this.rootStore
            .api('/resources', {
              params: { floorId },
              token: token || this.rootStore.settings.token,
              label: 'resources',
            })
            .then(
              action('fetchResourcesSuccess', res => {
                if (res && res.data && res.data.data) {
                  this.items.forEach(floor => {
                    if (floor.id === floorId) {
                      floor.resources = res.data.data;
                    }
                  });
                } else {
                  this.items.replace([]);
                }
              })
            )
        );
      }),

      fetchFloorAssets: action(
        'fetchFloorAssets',
        ({ buildingId, floorId, token }) => {
          this.rootStore.floors.fetchResources({
            floorId,
          });
        }
      ),
    });

    /************************************************
     * Observers
     *************************************************/

    // Set the selected floor when the floors change.
    // FIXME: This should be removed when the actual default floor is provided by the
    // settings API. This has not yet been implemented by server.
    observe(this.items, change => {
      if (change.type === 'splice' && !this.rootStore.ui.floorId) {
        this.rootStore.ui.setFloor((this.defaultFloor || {}).id);
      }
    });
  }

  floorOverlayById = floorId => {
    return floorToOverlay(this.floorById(floorId));
  };
}

export default FloorsStore;
