import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: table;
  text-align: center;
`;

const SpinnerCellWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
  margin: auto;
`;

const StyledCircle = styled.circle`
  stroke-dasharray: 1400;
  stroke-dashoffset: 0;
  animation: rotator 1.4s ease-in-out infinite, dash 1.4s ease-in-out infinite;
  transform-origin: center;

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1400;
    }
    50% {
      stroke-dashoffset: 1000;
      transform: rotate(160deg);
    }
    100% {
      stroke-dashoffset: 1400;
      transform: rotate(360deg);
    }
  }
`;

const Spinner = ({ size, fullPage }) => {
  const sizeMap = {
    small: '3em',
    medium: '7em',
    large: '10em',
  };

  const containerStyles = {
    height: fullPage ? 600 : null,
    width: fullPage ? '100%' : null,
  };

  return (
    <SpinnerContainer style={containerStyles}>
      <SpinnerCellWrapper>
        <svg
          height={sizeMap[size]}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 468 468"
        >
          <g>
            <circle
              cx="234"
              cy="234"
              r="222"
              fill="none"
              stroke="#eeeeee"
              strokeWidth="22"
            />
            <StyledCircle
              cx="234"
              cy="234"
              r="222"
              fill="none"
              stroke="#3d3d3d"
              strokeLinecap="round"
              strokeWidth="22"
            />
          </g>
        </svg>
      </SpinnerCellWrapper>
    </SpinnerContainer>
  );
};
Spinner.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullPage: PropTypes.bool,
};
Spinner.defaultProps = {
  size: 'medium',
};

export default Spinner;
