import alertEmitter from './alertEmitter';

// localStorage wrapper - catch browsers that disable localStorage
// looking at you Safari Private Mode.
export const ls = type => (
  key,
  val,
  { _localStorage = global.localStorage, _alertEmitter = alertEmitter } = {}
) => {
  try {
    return _localStorage[type](key, val);
  } catch (e) {
    if (!__PROD__ && !__TEST__) {
      console.error('Could not reach localStorage'); // eslint-disable-line no-console
    }
    _alertEmitter.show(
      `Could not reach local storage. If you're viewing this website in private mode, please disable it.`,
      'danger'
    );
  }
};

export const setItem = ls('setItem');
export const removeItem = ls('removeItem');
export const getItem = ls('getItem');

export const getDefaultCampus = ({ _getItem = getItem } = {}) => {
  const storageCampusId = parseInt(_getItem('campusId', null), 10);
  if (!isNaN(storageCampusId)) {
    return storageCampusId;
  }
};
