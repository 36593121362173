export const DIRECTIONS = {
  UNKNOWN: 'unknown',
  TURN_LEFT: 'Turn left',
  TURN_RIGHT: 'Turn right',
  BEAR_LEFT: 'Bear left',
  BEAR_RIGHT: 'Bear right',
  SLIGHT_LEFT: 'Slight left',
  SLIGHT_RIGHT: 'Slight right',
  HARD_LEFT: 'Hard left',
  HARD_RIGHT: 'Hard right',
  U_TURN: 'U-Turn',
  STRAIGHT: 'Straight',
  CHANGE_LEVEL: 'Change level',
  DESTINATION: 'Destination',
};

export const WALKING_SPEED = 3.1; // in MPH
