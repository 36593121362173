import { autorun, useStrict } from 'mobx';
import HistoryStore from './HistoryStore';
import UiStore from './UiStore';
import SettingsStore from './SettingsStore';
import CampusesStore from './CampusesStore';
import BuildingsStore from './BuildingsStore';
import FloorsStore from './FloorsStore';
import PointsStore from './PointsStore';
import RoutesStore from './RoutesStore';
import CategoriesStore from './CategoriesStore'
import api from '../utils/api';
import ImageCacheStore from './ImageCacheStore';

// Enable Mobx strict mode - no state mutations outside actions.
// https://github.com/mobxjs/mobx/blob/gh-pages/docs/refguide/api.md#usestrict
useStrict(true);

class RootStore {
  constructor(_api, { _history } = {}) {
    this.api = _api;

    // NOTE: It's important these three stores are intiated first as the
    // other stores depend on them.
    this.history = new HistoryStore({ history: _history });
    this.settings = new SettingsStore(this);
    this.ui = new UiStore(this);

    // These stores can be initiated in any order - just as long as the
    // three above are created first.
    this.campuses = new CampusesStore(this);
    this.buildings = new BuildingsStore(this);
    this.floors = new FloorsStore(this);

    // The PointsStore relies on the RoutesStore.
    this.routes = new RoutesStore(this);
    this.points = new PointsStore(this);
    this.categories = new CategoriesStore(this);
    this.images = new ImageCacheStore(this);
  }
}

let store;
if (!__TEST__) {
  store = new RootStore(api);
}

if (__LOCAL__) {
  global.store = store;

  autorun('Local Debug Autorun', () => {
    console.log('autorun:', store); // eslint-disable-line no-console
  });
}

export { RootStore };
export default store;
