import { extendObservable, action, computed } from 'mobx';
import alertEmitter from '../utils/alertEmitter';
import TrackRequest from '../utils/TrackRequest';

class SettingsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.trackRequest = new TrackRequest();

    extendObservable(this, {
      androidAppLink: null,
      backgroundColor: null,
      distanceUnits: null,
      hostDomain: null,
      iconButtonColor: null,
      iosAppLink: null,
      logoLink: null,
      logoS3Url: null,
      navBarColor: null,
      orgId: null,
      routeColor: null,
      siteWideFont: null,
      siteWideFontSize: null,
      token: null,
      defaultConfiguration: [],
      errorCode: null,

      /************************************************
       * Computed
       *************************************************/

      isMetric: computed(() => {
        return this.distanceUnits === 'METERS' ? true : false;
      }),

      has404Error: computed(() => {
        return !this.trackRequest.isLoading && this.errorCode === 404;
      }),

      /************************************************
       * Actions
       *************************************************/

      setToMetric: action('setToMetric', () => (this.distanceUnits = 'METERS')),

      setToImperial: action(
        'setToImperial',
        () => (this.distanceUnits = 'FEET')
      ),

      /**
       * Fetches settings from the server
       */
      loadSettings: action(
        'loadSettings',
        ({ _alertEmitter = alertEmitter } = {}) => {
          // Reset the errorCode on each request.
          this.errorCode = null;

          this.trackRequest.track(
            this.rootStore
              .api(`/domain-web-settings`, { label: 'settings' })
              .then(
                action('loadSettingsSuccess', res => {
                  if (!res) {
                    this.errorCode = 404;
                    return;
                  }

                  // Assign the new values to the observable.
                  Object.keys(res.data.webSettings).forEach(key => {
                    this[key] = res.data.webSettings[key];
                  });

                  // Set the default configuration which is all WWF-enabled
                  // campuses and the default building and floor for each.
                  this.defaultConfiguration.replace(
                    res.data.defaultConfiguration
                  );

                  this.setToken(res.token);
                })
              )
          );
        }
      ),

      setToken: action('setToken', token => {
        this.token = token;
        // Once the token is set, bootstrap the application.
        this.rootStore.ui.bootstrap();
      }),
    });

    this.loadSettings();
  }
}

export default SettingsStore;
