import { extendObservable, action, computed } from 'mobx';

class TrackRequest {
  constructor() {
    extendObservable(this, {
      __numRequests: 0,

      isLoading: computed(function() {
        return Boolean(this.__numRequests);
      }),

      track: action(function(promise) {
        // Check if the arugment is a Promise.
        if (!Promise.resolve(promise) === promise) {
          // eslint-disable-next-line no-console
          console.warn('trackRequest requires a Promise.');
          return;
        }

        this.__numRequests++;

        return promise
          .then(
            action(res => {
              // Decrement the number of requests, but don't go below zero.
              this.__numRequests = this.__numRequests
                ? this.__numRequests - 1
                : 0;
              return res;
            })
          )
          .catch(
            action(err => {
              this.__numRequests = this.__numRequests
                ? this.__numRequests - 1
                : 0;
              throw err;
            })
          );
      }),
    });
  }
}

export default TrackRequest;
