export const defaultIcon = type =>
  `https://lbs-prod.s3.amazonaws.com/stock_assets/icons/${type}.png`;

export const metersToFeet = (meters, round = true) => {
  const feet = meters * 3.28084;
  return round ? Math.ceil(feet) : feet;
};

export const toRgba = (color, alpha) => {
  let _color = color;
  alpha = !!alpha ? alpha : 1;
  if(!_color){
      return `rgba(0, 128, 255, ${alpha})`;
  }
  _color = _color.charAt(0) === '#' ? _color.substr(1) : _color;
  try{
    _color = _color.length === 3 ?     _color = _color.substr(0, 1) + _color.substr(0, 1) + _color.substr(1, 2) + _color.substr(1, 2) + _color.substr(2, 3) + _color.substr(2, 3) : _color;
    let r = parseInt(`${_color.charAt(0)}${_color.charAt(1)}`,16);
    let g = parseInt(`${_color.charAt(2)}${_color.charAt(3)}`,16);
    let b = parseInt(`${_color.charAt(4)}${_color.charAt(5)}`,16);
    return `rgba(${r},${g},${b},${alpha})`;
  }catch(err){
    //If the hex was weirder than #fff return that let someone else handle the color
    return color;
  }
};
