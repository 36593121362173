import { extendObservable, computed, action } from 'mobx';

class CampusesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      items: computed(() => {
        const { defaultConfiguration } = this.rootStore.settings;

        return defaultConfiguration.map(config => {
          return {
            id: config.campusId,
            name: config.campusName,
          };
        });
      }),

      /************************************************
       * Computed Properties (selectors)
       *************************************************/

      hasCampuses: computed(() => {
        return !!this.items.length;
      }),

      selectedCampus: computed(() => {
        const selectedCampusId = this.rootStore.ui.campusId;
        return this.items.find(campus => campus.id === selectedCampusId);
      }),

      /************************************************
       * Actions
       *************************************************/

      fetchVenueByCampusId: action(
        'fetchCampus',
        async ({ id, token = '' } = {}) => {
          const campus = await this.rootStore
            .api(`/campuses/${id}`, {
              token: token || this.rootStore.settings.token,
              label: 'campus',
            })
            .then(res => res.data);

          if (!campus || !campus.venueGuid) return null;

          return await this.rootStore
            .api(`/venueInfo`, {
              token: token || this.rootStore.settings.token,
              label: 'venueInfo',
              method: 'post',
              body: {venueId: campus.venueGuid}
            })
            .then(res => {
              return res.data;
            });
        }
      ),
    });
  }

  campusById = id => {
    return this.items.find(c => c.id === id);
  };
}

export default CampusesStore;
