import { extendObservable, action, computed } from 'mobx';
import get from 'lodash/get';

import { WALKING_SPEED, DIRECTIONS } from '../constants';
import { metersToFeet } from '../utils/helpers';
import alertEmitter from '../utils/alertEmitter';
import TrackRequest from '../utils/TrackRequest';

class RoutesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.trackRequest = new TrackRequest();

    extendObservable(this, {
      items: [],
      directions: [],
      // An array of floor id's who's map's tiles have loaded on the print view.
      printViewFloorMapsLoaded: [],

      /************************************************
       * Computed Properties (selectors)
       *************************************************/

      havePrintViewFloorMapsLoaded: computed(() => {
        return !this.directionsByFloor.some(
          direction =>
            this.printViewFloorMapsLoaded.indexOf(direction.floorId) === -1
        );
      }),

      directionsByFloor: computed(() => {
        // Group the directions by floor
        // [{ floorId: 1, steps: [...]}, {floorId: 2, steps: [...]}, ...]
        let floorIdx = 0;
        const directionsByFloor = this.directions.reduce(
          (floors, direction) => {
            // Since the directions are linear (A to B), when the direction floorId changes, all
            // following directions will be a new floor.
            if (
              floors.length &&
              floors[floorIdx] &&
              floors[floorIdx].floorId !== direction.floorId
            ) {
              floorIdx++;
            }

            const floor = floors[floorIdx] || {
              floorId: direction.floorId,
              pointId: direction.points[0].id,
              steps: [],
            };

            // If this is the first time we're creating the floor, store the complete point
            // object for later use. We'll need this if the user switches floors and all the
            // points are cleared out.
            if (!floors[floorIdx]) {
              this.rootStore.points.pointsCache[
                direction.points[0].id
              ] = this.rootStore.points.getPointById(direction.points[0].id);
            }

            // Add the directions to the floor's steps.
            floor.steps.push(direction);
            floors[floorIdx] = floor;
            return floors;
          },
          []
        );

        // Lastly, compute the steps direction and distance.
        return directionsByFloor.map(direction => {
          return {
            ...direction,
            steps: direction.steps
              ? direction.steps.map(step => {
                return {
                  step: step,
                  direction: DIRECTIONS[step.direction],
                  distance: this.rootStore.settings.isMetric
                    ? `${Math.ceil(step.distance)} meters`
                    : `${metersToFeet(step.distance)} feet`,
                };
              })
              : null,
          };
        });
      }),

      totalDistanceInMeters: computed(() => {
        const distance = this.directions.reduce((distance, step) => {
          return distance + Math.ceil(step.distance);
        }, 0);
        return distance;
      }),

      totalDistanceInFeet: computed(() => {
        return metersToFeet(this.totalDistanceInMeters);
      }),

      // Time from pointA to pointB in minutes.
      timeToDestination: computed(() => {
        const feetInMile = 5280; // cause this makes sense - :facepalm: imperial.
        const distance = this.totalDistanceInFeet / feetInMile; // distance in miles.
        const time = distance / WALKING_SPEED;
        return Math.ceil(time * 60); // convert to minutes, round up to nearest minute.
      }),

      /************************************************
       * Actions
       *************************************************/

      printViewFloorTilesLoaded: action(
        'printViewFloorTilesLoaded',
        floorId => {
          if (this.printViewFloorMapsLoaded.indexOf(floorId) !== -1) {
            return;
          }

          this.printViewFloorMapsLoaded.push(floorId);
        }
      ),

      fetchDirections: action(
        'fetchDirections',
        ({ pointAId, pointBId, isAccessible, token, _alertEmitter = alertEmitter } = {}) => {
          // Reset the points cache when we fetch a new route.
          this.rootStore.points.clearPointsCache();
          let options = {
            params: {
              startPointId: pointAId,
              endPointId: pointBId,
              draftStatus: 'LIVE'
            },
            token: token || this.rootStore.settings.token,
            label: 'directions',
          };
          if (isAccessible) {
            //found that the API had issues if false... not sure why
            options.params.isAccessible = isAccessible;
          }
          this.trackRequest.track(
            this.rootStore
              .api('/routes/directions', options)
              .then(
                action('fetchDirectionsSuccess', res => {
                  const steps = get(res, 'data.data[0].steps', []);
                  this.directions.replace(
                    steps.map(step => ({
                      ...step,
                      floorId: step.points[0].floorId,
                    }))
                  );

                  // Reset the floors whose maps have loaded.
                  this.printViewFloorMapsLoaded.replace([]);
                  if (!steps.length) {
                    _alertEmitter.show(
                      `Sorry, we don't have any information on that route.`,
                      'warning'
                    );
                  }
                })
              ).catch(err => {
                console.log("we got here")
              })
          );
        }
      ),
    });
  }

  getDirectionsByFloorId = floorId => {
    const directions = this.directionsByFloor;
    return directions.find(d => d.floorId === floorId);
  };

  // Returns a list of point id's from directions for a given floor.
  // Use this method combined with a method from the PointsStore to get
  // a list of points.
  // NOTE: The directions endpoint does not return all point data, hence
  // the requirement to use a method from the PointsStore.
  pointIdsByFloorId = floorId => {
    const floorDirections = this.getDirectionsByFloorId(floorId);
    if (!floorDirections) {
      return [];
    }

    const points = [];
    const pointsById = {};

    floorDirections.steps.forEach(step => {
      if (!step.step) {
        return;
      }

      step.step.points.forEach(point => {
        if (pointsById[point.id]) {
          return;
        }

        pointsById[point.id] = true;
        points.push(point);
      });
    });

    return points.map(p => p.id);
  };

  routePathByFloorId = floorId => {
    const floorDirections = this.getDirectionsByFloorId(floorId);
    if (!floorDirections) {
      return [];
    }

    const points = [];
    const pointsById = {};

    floorDirections.steps.forEach(step => {
      if (!step.step) {
        return;
      }

      step.step.points.forEach(point => {
        if (pointsById[point.id]) {
          return;
        }

        pointsById[point.id] = true;
        points.push(point);
      });
    });

    return points.map(point => {
      return {
        lat: get(point, 'location.latitude'),
        lng: get(point, 'location.longitude'),
      };
    });
  };
}

export default RoutesStore;
