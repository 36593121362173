import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './../Spinner';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import { decorate } from 'value-pipeline';
import _WebFont from 'webfontloader';
import { injectGlobal } from 'styled-components';
import MediaQuery from 'react-responsive';

import AppDownload from '../AppDownload';
import ErrorPage from '../ErrorPage';
import { FONTS, FONT_SIZES } from '../../config';

// Load and display either the print layout or default layout.
const layouts = {
  print: import(/* webpackChunkName: "print" */ '../PrintLayout/PrintLayout'),
  shared: import(/* webpackChunkName: "print" */ '../DirectionsLayout'),
  default: import(/* webpackChunkName: "layout" */ '../Layout/Layout')
};

const _getLayoutImport = printMode =>  printMode && layouts[printMode] ? layouts[printMode] : layouts.default;

class App extends React.Component {
  static propTypes = {
    store: PropTypes.shape({
      settings: PropTypes.shape({
        trackRequest: PropTypes.shape({
          isLoading: PropTypes.bool.isRequired,
        }),
      }).isRequired,
      ui: PropTypes.shape({
        showPrint: PropTypes.bool.isRequired,
      }).isRequired,
    }),
    // Provided by defaultProps
    getLayoutImport: PropTypes.func.isRequired,
    WebFont: PropTypes.object.isRequired,
  };
  static defaultProps = {
    getLayoutImport: _getLayoutImport,
    WebFont: _WebFont,
  };

  state = {
    Layout: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      store: { ui, settings },
      WebFont,
    } = this.props;
    this.loadLayout(ui.mode);

    // Watch
    this.showPrintDispose = autorun('AppShowPrintAutorun', () => {
      this.loadLayout(ui.mode);
    });

    // Dynamically load the Google fonts.
    this.loadFontDispose = autorun('LoadFontAutrun', () => {
      if (settings.siteWideFont && FONTS[settings.siteWideFont]) {
        WebFont.load({
          google: {
            families: [FONTS[settings.siteWideFont]],
          },
          active: () => {
            injectGlobal`
              body {
                font-family: ${FONTS[settings.siteWideFont]}, Sans-Serif;
              }
            `;
          },
        });
      }
    });

    this.fontSizeDispose = autorun('FontSizeAutorun', () => {
      if (settings.siteWideFontSize && FONT_SIZES[settings.siteWideFontSize]) {
        injectGlobal`
          html, body {
            font-size: ${FONT_SIZES[settings.siteWideFontSize]}px;
          }
        `;
      }
    });
  }

  componentWillUnmount() {
    this.showPrintDispose();
    this.loadFontDispose();
    this.fontSizeDispose();
  }

  loadLayout(printMode) {
    this.props
      .getLayoutImport(printMode)
      .then(Layout => this.setState({ Layout: Layout.default }))
      .catch(error => {
        if (!__PROD__) {
          console.error(error); // eslint-disable-line no-console
        }
        throw new Error('Error loading Layout module.');
      });
  }

  render() {
    const { Layout } = this.state;
    const { settings, ui } = this.props.store;

    if (settings.trackRequest.isLoading || !Layout) {
      return <Spinner fullPage />;
    }

    if (settings.has404Error) {
      return <ErrorPage />;
    }

    // Media queries based on Zurb Foundation's
    // https://foundation.zurb.com/sites/docs/v/5.5.3/media-queries.html
    // https://webmasters.stackexchange.com/questions/82142/is-there-a-single-set-of-media-queries-for-all-the-devices
    return (
      <React.Fragment>
        {/* Small screen max-width 640px */
        ui.mode !== 'shared' &&
        (<MediaQuery maxDeviceWidth="40em">
          <AppDownload
            iosLink={settings.iosAppLink}
            androidLink={settings.androidAppLink}
          />
        </MediaQuery>)
        }
        {/* Medium screen min-width 641px + or UI Mode is shared route steps */
          ui.mode === 'shared' ? (<Layout />) : (<MediaQuery minDeviceWidth="40.063em">
            <Layout />
          </MediaQuery>)
        }
      </React.Fragment>
      
    );
  }
}

export { App as _App };
export default decorate(inject('store'), observer, App);
