import {
  extendObservable,
  action,
  computed,
  observable,
  observe,
  autorun,
} from 'mobx';
import uniqBy from 'lodash/uniqBy';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import debounce from 'debounce-promise';
import { defaultIcon } from '../utils/helpers';
import TrackRequest from '../utils/TrackRequest';

const findPointsWithMetadata = (points, search) => {
  let normalized = search ? search.toLowerCase().trim() : "";
  return points.filter(item => {
    let found = item.name.toLowerCase().indexOf(normalized) >= 0;
    if (!found) {
      let keywords = item.keywords;
      for (let i = 0; i < keywords.length; i++) {
        let val = keywords[i];
        if (val && val.toLowerCase().indexOf(normalized) >= 0) {
          found = true;
          break;
        }
      }
    }
    if (!found && item.subTitle) {
      found = item.subTitle.toLowerCase().indexOf(normalized) >= 0;
    }
    if (!found && item.category && item.category.description) {
      found = item.category.description.toLowerCase().indexOf(normalized) >= 0;
    }
    return found;
  });
};

const observablePoint = (rootStore, point) => {
  return observable({
    ...point,
    name: !point.name ? "" : point.name,


    /************************************************
     * Computed Properties (selectors)
     *************************************************/

    get iconUrl() {
      return point.customIconImageUrl || defaultIcon(point.poiType);
    },

    get lat() {
      return get(point, 'location.latitude');
    },

    get lng() {
      return get(point, 'location.longitude');
    },
    building: computed(() => {
      return rootStore.buildings.buildingById(point.buildingId);
    }),

    floor: computed(() => {
      return rootStore.floors.floorById(point.floorId);
    }),
    category: computed(() => {
      if (point.poiType) {
        let { categories } = rootStore;
        if (categories.hasCategories) {
          return categories.items.find(category => category.id === point.poiType);
        }
      }
    }),
    locationLabel: computed(() => {
      //New utility to normalize the rendering of the building name + floor level that used to be
      //performed at rendering time
      let building = rootStore.buildings.buildingById(point.buildingId);
      let floor = rootStore.floors.floorById(point.floorId);
      if (building && floor) {
        return `${building.name}, Level ${floor.level}`;
      }
      return "";
    }),
    subTitle: computed(() => {
      //New subTitle
      let result = "";
      if (point.metaData) {
        for (let key in point.metaData) {
          let nkey = (key ? key.toLowerCase() : "").trim();
          if (nkey === "subtitle" || nkey === "sub-title") {
            result = point.metaData[key];
            break;
          }
        }
      }
      return result;
    }),
    keywords: computed(() => {
      if (point.metaData) {

        for (let key in point.metaData) {
          if ((key.toLowerCase() === "keywords" || key.toLowerCase() === "keyword") && point.metaData[key]) {
            return point.metaData[key].split(" ");
          }
        }
      }
      return [];
    })
  });
};

class PoiQuery {
  constructor(
    rootStore,
    trackRequest,
    {
      getQueryString,
      setQueryString,
      isActive,
      _debounce = debounce,
      _observablePoint = observablePoint,
    }
  ) {
    this.rootStore = rootStore;
    this.trackRequest = trackRequest;

    this.getQueryString = getQueryString;
    this.setQueryString = setQueryString;
    this._isActive = isActive;

    this._fetchPointsForQuery = action('fetchPointsForQuery', params => {
      const searchParams = {
        name: params.queryStr,
        poiOnly: true,
        isActive: true,
      };
      // Search the entire campus or just the building?
      if (!params.buildingId) {
        searchParams.campusId = params.campusId;
      } else {
        searchParams.buildingId = params.buildingId;
      }

      this._clearQueryResults();
      //We already have all building points, only if searching by campus we let it be an API call
      if (searchParams.buildingId && this.rootStore.points.items.length > 0) {
        this.queryResults = findPointsWithMetadata(this.rootStore.points.items, searchParams.name);
        this.resultParams = params;
        return;
      }

      // For fetching all campus points by name.
      // http://localhost:3001/api/points?campusId=5489&offset=0&limit=100&name=room
      return this.rootStore.api
        .allEntities('/points', {
          params: searchParams,
          label: 'points search',
          token: this.rootStore.settings.token,
        })
        .then(
          action('fetchQueryPointsSuccess', points => {
            if (!isEqual(params, this.currentParams)) {
              // ignore stale response
              return;
            }
            this.resultParams = params;
            this.queryResults = points.map(p =>
              _observablePoint(this.rootStore, p)
            );
          })
        );
    });
    this._debouncedFetch = _debounce(this._fetchPointsForQuery, 300, {
      leading: true,
    });

    this._clearQueryResults = action(
      '_clearQueryResults',
      () => (this.queryResults = [])
    );

    this._setQueryResults = action(
      '_setQueryResults',
      (results) => (this.queryResults = results)
    );

    extendObservable(this, {
      queryResults: [],
      // keep track of the params that were used
      // to fetch the current results
      resultParams: null,

      get isActive() {
        return this._isActive();
      },

      get currentParams() {
        const params = {
          queryStr: (this.getQueryString() || '').trim(),
        };
        if (
          !this.rootStore.ui.buildingId ||
          this.rootStore.ui.buildingId === 'all-buildings'
        ) {
          params.campusId = this.rootStore.ui.campusId;
        } else {
          params.buildingId = this.rootStore.ui.buildingId;
        }
        return params;
      },

      clearQueryString: action('clearQueryString', () =>
        this.setQueryString('')
      ),
    });
  }

  bootstrap() {
    this._dispose = [
      autorun(() => {
        if (this.isActive && !isEqual(this.currentParams, this.resultParams)) {
          this._clearQueryResults();
          this.trackRequest.track(this._debouncedFetch(this.currentParams));
        }
      }),
    ];
    return this.dispose.bind(this);
  }

  redoSearch() {
    this._clearQueryResults();
    if (this.currentParams.buildingId && this.rootStore.points.items) {
      this._setQueryResults(findPointsWithMetadata(this.rootStore.points.items, this.currentParams.queryStr));
      return;
    }
  }

  dispose() {
    this._dispose.forEach(d => d());
  }
}
export { PoiQuery as _PoiQuery };

class PointsStore {
  constructor(rootStore, { _debounce } = {}) {
    this.rootStore = rootStore;
    this.trackRequest = new TrackRequest();

    extendObservable(this, {
      // The floor's POIs
      items: [],
      pointA: null,
      pointB: null,
      fetchPointsComplete: { complete: false },
      // Temporary cache of points by id use for routing when switching floors.
      // { [pointId]: {...}, ... }
      pointsCache: {},

      // Query objects
      searchQuery: new PoiQuery(this.rootStore, this.trackRequest, {
        _debounce,
        isActive: () => this.currentQuery === this.searchQuery,
        // Proxy UiStore's queryStr
        getQueryString: () => this.rootStore.ui.queryStr,
        setQueryString: this.rootStore.ui.setQuery.bind(this.rootStore),
      }),
      pointAQueryString: '',
      pointAQuery: new PoiQuery(this.rootStore, this.trackRequest, {
        _debounce,
        isActive: () => this.currentQuery === this.pointAQuery,
        getQueryString: () => this.pointAQueryString,
        setQueryString: val => this.setPointQueryString('pointA', val),
      }),
      pointBQueryString: '',
      pointBQuery: new PoiQuery(this.rootStore, this.trackRequest, {
        _debounce,
        isActive: () => this.currentQuery === this.pointBQuery,
        getQueryString: () => this.pointBQueryString,
        setQueryString: val => this.setPointQueryString('pointB', val),
      }),
      currentPointQuery: null,

      get currentQuery() {
        if (this.rootStore.ui.showDirections) {
          if (this.currentPointQuery === 'pointA') {
            return this.pointAQuery;
          } else if (this.currentPointQuery === 'pointB') {
            return this.pointBQuery;
          } else if (
            this.rootStore.ui.pointAId &&
            !this.rootStore.ui.pointBId
          ) {
            // Automatically search destination if only starting point is set
            return this.pointBQuery;
          } else {
            // Otherwise search for a starting point
            return this.pointAQuery;
          }
        }
        if (this.rootStore.ui.queryStr) {
          return this.searchQuery;
        } else {
          return undefined;
        }
      },

      get hasQuery() {
        return Boolean(this.currentQuery && this.currentQuery.getQueryString());
      },

      getPointById: pointId => {
        if (pointId === (this.pointA || {}).id) return this.pointA;
        if (pointId === (this.pointB || {}).id) return this.pointB;
        // Check here just in case. We cache the route points in
        // case the user switch floors.
        if (this.pointsCache[pointId]) {
          return this.pointsCache[pointId];
        }
        return (this.items || []).find(point => point.id === pointId);
      },

      /************************************************
       * Computed Properties (selectors)
       *************************************************/

      hasPoints: computed(() => {
        return !!this.items.length;
      }),

      selectedPoint: computed(() => {
        const selectedPointId = this.rootStore.ui.selectedPointId;

        if (!selectedPointId) {
          return null;
        }

        const point = this.currentQuery
          ? this.currentQuery.queryResults.find(
            point => point.id === selectedPointId
          ) || null
          : this.items.find(point => point.id === selectedPointId) || null;

        return point;
      }),

      // Get all points that aren't waypoints sorted alphabetically.
      // If there's query results, combine the POI's.
      // NOTE: While points are being fetched, the order will change.
      pointsAsMarker: computed(() => {
        const { isAccessible } = this.rootStore.ui;

        let markers = this.hasQuery ? this.currentQuery.queryResults : this.items;

        // Filter out all POI's that are waypoints - (waypoints = poiType === 0)
        // If required, filter out the non-accessbile markers. Used for directions.
        markers = markers.filter(
          p => p.poiType && (isAccessible ? p.isAccessible : true)
        );

        // Sort the markers in alphabetical order.
        markers.sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          if (aName < bName) return -1;
          if (aName > bName) return 1;
          return 0;
        });

        return markers;
      }),

      // The map should only show the floor's POIs.
      floorPointsAsMarker: computed(() => {
        const ui = this.rootStore.ui;

        // If we're showing directions, then only show the starting and ending
        // point of the route for this floor.
        const directionsMode = ui.showDirections && this.pointA && this.pointB;
        if (directionsMode) {
          return this.routePointsByFloorId(ui.floorId);
        }

        const points = this.pointsAsMarker.filter(
          p => p.floorId === ui.floorId
        );

        // PointA and PointB should always show on the map. If a user
        // searches for a point while one is selected for directions,
        // it could filter the start/end point(s) from the map.
        if (
          this.pointB &&
          this.pointB.floorId === this.rootStore.ui.floorId &&
          !points.find(point => point.id === this.pointB.id)
        ) {
          points.push(this.pointB);
        }

        if (
          this.pointA &&
          this.pointA.floorId === this.rootStore.ui.floorId &&
          !points.find(point => point.id === this.pointA.id)
        ) {
          points.push(this.pointA);
        }

        return points;
      }),

      /************************************************
       * Actions
       *************************************************/
      clearPointsCache: action('clearPointsCache', () => {
        this.pointsCache = {};
      }),

      setPointQuery: action('setPointQuery', point => {
        if (point && point !== 'pointA' && point !== 'pointB') {
          throw new Error(
            `setPointQuery input must be "pointA" or "pointB", got "${point}"`
          );
        }
        this.currentPointQuery = point;
      }),

      setPointQueryString: action(
        'setPointQueryString',
        (point, queryString) => {
          if (point === 'pointA') {
            this.pointAQueryString = queryString;
          } else if (point === 'pointB') {
            this.pointBQueryString = queryString;
          } else {
            throw new Error(
              `setPointQuery input must be "pointA" or "pointB", got "${point}"`
            );
          }
        }
      ),

      clearPointSearches: action('clearPointSearches', () => {
        this.setPointQueryString('pointA', '');
        this.setPointQueryString('pointB', '');
        this.setPointQuery(null);
      }),

      setPointA: action('setPointA', id => {
        this.setPointQueryString('pointA', '');
        const point = this.pointsAsMarker.find(point => point.id === id);
        if (point) {
          this.pointA = point;
        } else {
          // If there's no matching point in the store, hit the api
          this.fetchPoint({ id }).then(
            action('fetchPointSuccess', data => (this.pointA = data))
          );
        }
      }),

      setPointB: action('setPointB', id => {
        this.setPointQueryString('pointB', '');
        const point = this.pointsAsMarker.find(point => point.id === id);
        if (point) {
          this.pointB = point;
        } else {
          // If there's no matching point in the store, hit the api
          this.fetchPoint({ id }).then(
            action('fetchPointSuccess', data => (this.pointB = data))
          );
        }
      }),

      clearPointA: action('clearPointA', () => {
        this.pointA = null;
      }),

      clearPointB: action('clearPointB', () => {
        this.pointB = null;
      }),

      fetchPoint: action('fetchPoint', ({ id, token }) => {
        if (!id) return;

        return this.trackRequest.track(
          this.rootStore
            .api(`/points/${id}`, {
              token: token || this.rootStore.settings.token,
              label: 'point',
            })
            .then(res => {
              if (res && res.data) {
                return observablePoint(this.rootStore, res.data);
              }
              return null;
            })
        );
      }),
      fetchFloorPoints: action('fetchFloorPoints', floorId => {
        if (!floorId) {
          return;
        }
        if (this.items.length === 0) {
          return;
        }
        return this.items.filter(point => point.floorId === floorId);
      }),
      fetchPoints: action(
        'fetchPoints',
        ({ floorId, buildingId, poiOnly = true, token } = {}) => {
          // We require at least one entity id.
          if (!floorId && !buildingId) {
            return;
          }

          // Clear out the old points, we don't want them hanging around
          // on floor switch.
          this.items.replace([]);

          // As points are loaded, store them.
          const progress = action('fetchPointsProgress', points => {
            if (points.length === 0) {
              return;
            }

            // Append points - collect up all the paginated points.
            this._mergePoints(points);
          });

          // TODO: If this action gets called right after the other, the
          // items is going to get messed up due to two Promises working
          // at the same time.
          // Fetch all the points for the floor.
          this.trackRequest.track(
            this.rootStore.api
              .allEntities('/points', {
                // Filter out the falsey values.
                params: pickBy(
                  {
                    floorId,
                    buildingId,
                    poiOnly,
                    isActive: true
                  },
                  identity
                ),
                label: 'points',
                token: token || this.rootStore.settings.token,
                progress,
              })
              .then(
                action('fetchPointsSuccess', points => {
                  this._mergePoints(points);

                  // If they have a point selected and the point is not in the
                  // new list of points, then unselect it.
                  if (
                    this.rootStore.ui.selectedPointId &&
                    !points.find(
                      point => point.id === this.rootStore.ui.selectedPointId
                    )
                  ) {
                    this.rootStore.ui.selectedPointId = null;
                  }
                  this.fetchPointsComplete.complete = true;
                })
              )
          );
        }
      ),

      clearPointsList: action('clearPointsList', () => {
        this.items.replace([]);
      }),
    });
  }

  bootstrap() {
    this._dispose = [
      this.searchQuery.bootstrap(),
      this.pointAQuery.bootstrap(),
      this.pointBQuery.bootstrap(),
      observe(this.rootStore.routes.directions, changes => {
        const buildingIds = this.rootStore.routes.directions.map(d => d.buildingId);
        // Fetch the points for all the buildings.
        uniq(buildingIds).forEach(buildingId => {
          this.rootStore.points.fetchPoints({ buildingId });
        });
      }),
      observe(this.rootStore.points.fetchPointsComplete, changes => {
        this.searchQuery.redoSearch();
      }),
      autorun(() => {
        // If they have a point selected and the point is not in the
        // new list of points, then unselect it.
        if (
          this.rootStore.ui.selectedPointId &&
          !this.selectedPoint &&
          // Make sure it's not just missing because the points list is loading, though
          !this.trackRequest.isLoading
        ) {
          this.rootStore.ui.clearSelectedPoint();
        }
      }),
    ];
    return this.dispose.bind(this);
  }

  dispose() {
    this._dispose.forEach(d => d());
  }

  _mergePoints(points) {
    this.items.replace(
      uniqBy(
        this.items
          .toJS()
          .concat(points.map(p => {
            let result = observablePoint(this.rootStore, p);
            return result;
          })),
        'id'
      )
    );
  }

  routePointsByFloorId = floorId => {
    const routePointIds = this.rootStore.routes.pointIdsByFloorId(floorId);

    // Only the first and last point are returned to ensure no other
    // points are shown on the map while viewing directions.
    const startingPoint = routePointIds[0];
    const endingPoint = routePointIds[routePointIds.length - 1];

    return this.pointsAsMarker.filter(
      p => p.id === startingPoint || p.id === endingPoint
    );
  };

  pointById = pointId => {
    return this.items.find(p => p.id === pointId);
  };
}

export default PointsStore;
