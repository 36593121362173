import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@phunware/cake-ui/lib/Button';
import bowser from 'bowser';
import { inject } from 'mobx-react';
import { decorate } from 'value-pipeline';

import Spinner from '../Spinner';

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Modal = styled.div`
  width: 90%;
  margin: 40px auto;
  background-color: #fff;
  padding: 24px;
  border-radius: 5px;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin: 0 0 2rem 0;
  line-height: 1.2em;
`;

const CustomButton = styled(Button)`
  width: 100%;
  height: 48px;
  margin: 24px 0 0 0;
`;

const AppDownloadButton = ({ handleClick, os }) => (
  <CustomButton onClick={handleClick}>
    <i
      className={`fa fa-${os}`}
      aria-hidden="true"
      style={{ fontSize: '1.2rem', marginRight: 8 }}
    />{' '}
    Download App
  </CustomButton>
);
AppDownloadButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  os: PropTypes.oneOf(['android', 'apple']),
};

class AppDownload extends React.Component {
  static propTypes = {
    iosLink: PropTypes.string,
    androidLink: PropTypes.string,
    location: PropTypes.object.isRequired,
    store: PropTypes.shape({
      campuses: PropTypes.shape({
        items: PropTypes.array,
        hasCampuses: PropTypes.bool.isRequired,
        fetchVenueByCampusId: PropTypes.func.isRequired,
      }).isRequired,
    }),
    isAndroid: PropTypes.bool.isRequired,
    isIos: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    location: global.location,
    isAndroid: !!bowser.android,
    isIos: !!bowser.ios,
  };

  state = {
    venue: {},
    isLoading: false,
  };

  componentDidMount() {
    const { campuses } = this.props.store;
    if (campuses.hasCampuses) {
      this.setState({ isLoading: true });
      campuses
        .fetchVenueByCampusId({ id: campuses.items[0].id })
        .then(venue => {
          this.setState({
            isLoading: false,
            venue,
          });
        });
    }
  }

  handleClick =(e, url) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.location.replace(url);
  }

  render() {
    const { iosLink, androidLink, isIos, isAndroid } = this.props;
    const { venue, isLoading } = this.state;

    if (isLoading) return <Spinner fullPage />;

    return (
      <Container>
        <Modal>
          <Title>Download the {venue.name} App</Title>
          <p>
            You'll receive a much better wayfinding experience downloading the
            mobile application.
          </p>
          {!isIos && (
            <AppDownloadButton
              handleClick={(e) => this.handleClick(e, androidLink)}
              os="android"
              data-testid="button"
            />
          )}

          {!isAndroid && (
            <AppDownloadButton
              handleClick={(e) => this.handleClick(e, iosLink)}
              os="apple"
            />
          )}
        </Modal>
      </Container>
    );
  }
}

export { AppDownload as _AppDownload, AppDownloadButton };
export default decorate(inject('store'), AppDownload);
