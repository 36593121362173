import { extendObservable, action } from 'mobx';
import qs from 'qs';
import createHistory from 'history/createBrowserHistory';

let browserHistory;
if (!__TEST__) {
  browserHistory = createHistory({
    basename: '',
  });
}

class HistoryStore {
  constructor({ history = browserHistory } = {}) {
    this.history = history;

    extendObservable(this, {
      location: {
        // The `history.location` object doesn't include the origin.
        origin: global.window.location.origin,
        ...history.location,
        query: this.query(history.location),
      },
    });

    // The URL is the source of truth, so update the store whenever the URL
    // changes.
    history.listen(action(this.listen.bind(this)));
  }

  query(location) {
    let search = location.search;

    // Remove the "?" from the search.
    if (search && search[0] === '?') {
      search = search.substr(1);
    }

    return qs.parse(search);
  }

  listen(newLocation) {
    this.location = {
      // The `history.location` object doesn't include the origin.
      origin: global.window.location.origin,
      ...newLocation,
      query: this.query(newLocation),
    };
  }

  _nextLocation(location) {
    if (!location.query) {
      return location;
    }

    return {
      ...location,
      search: '?' + qs.stringify(location.query),
    };
  }

  push(location, state) {
    const nextLocation = this._nextLocation(location);
    this.history.push(nextLocation, state);
  }

  replace(location, state) {
    const nextLocation = this._nextLocation(location);
    this.history.replace(nextLocation, state);
  }
}

export default HistoryStore;
