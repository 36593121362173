import { extendObservable, observe, action, computed } from 'mobx';
import {
  getItem as _getItem,
  setItem as _setItem,
  removeItem as _removeItem,
  getDefaultCampus as _getDefaultCampus,
} from '../utils/ls';

class UiStore {
  constructor(
    rootStore,
    {
      getItem = _getItem,
      setItem = _setItem,
      removeItem = _removeItem,
      getDefaultCampus = _getDefaultCampus,
    } = {}
  ) {
    this.rootStore = rootStore;

    // Get the campus id from local storage as backup if not in the URL.
    let campusId = this.getIntFromQuery('campus');

    if (!campusId) {
      const storageCampusId = getDefaultCampus();
      campusId = storageCampusId || campusId;
    }

    extendObservable(this, {
      campusId,
      buildingId: this.getIntFromQuery('building'),
      floorId: this.getIntFromQuery('floor'),
      selectedPointId: this.getIntFromQuery('selectedPoint'),
      pointAId: this.getIntFromQuery('startingPoint'),
      pointBId: this.getIntFromQuery('destinationPoint'),
      queryStr: this.getFromQuery('query'),
      mode: this.getFromQuery('mode'),
      isAccessible: !!this.getFromQuery('accessible'),
      centerPoint: this.getFromQuery('centerPoint'),
      kiosk: this.getFromQuery('kiosk'),

      /************************************************
       * Computed
       *************************************************/

      hasQuery: computed(() => {
        return !!this.queryStr;
      }),

      showDirections: computed(() => {
        return this.mode === 'directions';
      }),

      showPrint: computed(() => {
        return this.mode === 'print';
      }),

      printUrl: computed(() => {
        return [
          this.rootStore.history.location.origin,
          '?',
          'mode=print',
          `&campus=${this.campusId}`,
          `&building=${this.buildingId}`,
          `&startingPoint=${this.pointAId}`,
          `&destinationPoint=${this.pointBId}`,
          this.isAccessible === true ? '&accessible=true' : ''
        ].join('');
      }),

      sharedUrl: computed(() => {
        return [
          this.rootStore.history.location.origin,
          '?',
          'mode=shared',
          `&campus=${this.campusId}`,
          `&building=${this.buildingId}`,
          `&startingPoint=${this.pointAId}`,
          `&destinationPoint=${this.pointBId}`,
          this.isAccessible === true ? '&accessible=true' : ''
        ].join('');
      }),

      getCenterPoint: computed(() => {
        if (this.centerPoint) {
          const coordinates = this.centerPoint.split(',');

          return {
            lat: parseFloat(coordinates[0]),
            lng: parseFloat(coordinates[1]),
          };
        } else {
          return null;
        }
      }),

      /************************************************
       * Actions
       *************************************************/

      // Kickoff the initial data fetch. Id's from the URL will be
      // set in the store above. We now need to fetch this data on
      // first load of the application. Updates to the ids are handled
      // in the respective component's onChange handlers.
      // This action is called from the settings store after the public
      // token is fetched.
      bootstrap: action('bootstrap', () => {
        const {
          campuses,
          buildings,
          floors,
          points,
          routes,
          settings,
          categories,
        } = this.rootStore;

        this.setMode(this.mode);

        // Bootstrap stores
        points.bootstrap();

        // If there's only one campus enabled, then we default to that.
        const defaultConfig =
          settings.defaultConfiguration.length === 1
            ? settings.defaultConfiguration[0]
            : null;

        // If there isn't a campus selected and there's no default config,
        // then there's nothing to bootstrap as the Campus Modal will popup.
        if (!this.campusId && !defaultConfig) {
          return;
        }

        // Check to see if the campus id is a valid campus. If it's not,
        // then use the default campus or do nothing.
        const hasValidCampus =
          this.campusId && campuses.campusById(this.campusId);
        if (!hasValidCampus) {
          if (defaultConfig) {
            this.campusId = defaultConfig.campusId;
          } else {
            // This will trigger the modal popup.
            this.campusId = null;
            return;
          }
        }

        // Grab the config for the campus to get the default building and floor.
        const campusConfig = settings.defaultConfiguration.find(
          config => config.campusId === this.campusId
        );

        if (!this.buildingId && campusConfig.buildingId) {
          this.buildingId = campusConfig.buildingId;
          if (!this.floorId && campusConfig.floorId) {
            this.floorId = campusConfig.floorId;
          }
        }
        if (!categories.hasCategories) {
          categories.fetchCategories();
        }
        // Fetch the buildings
        buildings.fetchBuildings({
          campusId: this.campusId,
        });

        // Fetch the floors
        if (this.buildingId) {
          points.fetchPoints({ buildingId: this.buildingId });
          floors.fetchFloors({
            buildingId: this.buildingId,
          });
        }

        // Fetch the points that match the user's query.
        if (this.queryStr && (this.buildingId || this.campusId)) {
          this.setQuery(this.queryStr);
        }

        // Reset the point to trigger necessary data fetches.
        if (this.pointAId) {
          points.setPointA(this.pointAId);
        }

        if (this.pointBId) {
          points.setPointB(this.pointBId);
        }

        // If both points are set, fetch the route and directions.
        if (this.pointBId && this.pointAId) {
          routes.fetchDirections({
            pointAId: this.pointAId,
            pointBId: this.pointBId,
            isAccessible: this.isAccessible
          });
        }
      }),

      setMode: action('setMode', (mode = '') => {
        switch (mode.toLowerCase()) {
          case 'directions':
            // FIXME: Do I need to add some basic state checks here?
            // ex: can't show directions if there's no campus, building and point ids.
            this.mode = 'directions';
            break;
          case 'print':
            this.mode = 'print';
            break;
          case 'shared':
            this.mode = 'shared';
            break;
          default:
            this.mode = '';
            break;
        }
      }),

      setCampus: action('setCampus', (campusId, persist) => {
        const { buildings, settings, categories } = this.rootStore;

        // When the campus changes, reset the view.
        this.floorId = null;
        this.buildingId = null;
        this.pointAId = null;
        this.pointBId = null;
        this.selectedPointId = null;
        this.campusId = parseInt(campusId, 10);
        this.setMode();

        // Fetch the campus' buildings.
        buildings.fetchBuildings({
          campusId,
        });

        if (!categories.hasCategories) {
          categories.fetchCategories();
        }
        // Optionally persist to local storage.
        if (persist) {
          setItem('campusId', this.campusId);
        }

        // Grab the config for the campus they just selected.
        const campusConfig = settings.defaultConfiguration.find(
          config => config.campusId === campusId
        );

        // If the config exists, then set the default building and floor.
        if (campusConfig && campusConfig.buildingId) {
          this.setBuilding(campusConfig.buildingId);
        }
      }),

      setBuilding: action('setBuilding', id => {
        const { floors, points, settings } = this.rootStore;

        const buildingId = id === 'all-buildings' ? id : parseInt(id, 10);
        if (this.buildingId === buildingId) return;

        this.buildingId = buildingId;

        // When the building changes, remove the selected floor.
        // If the building id is 'all-buildings', don't reset the floor.
        if (buildingId !== 'all-buildings') {
          points.clearPointsList();
          floors.fetchFloors({ buildingId });
          points.fetchPoints({ buildingId });
          // Grab the config for the campus based on the building
          // they just selected.
          const campusConfig = settings.defaultConfiguration.find(
            config => config.buildingId === id
          );

          // If the config exists, then set the default floor.
          if (campusConfig && campusConfig.floorId) {
            this.setFloor(campusConfig.floorId);
          }
        }
      }),

      setFloor: action('setFloor', id => {
        const floorId = parseInt(id, 10);
        if (this.floorId === floorId) return;

        this.floorId = floorId;

        // On change, fetch the new floor assets.
        this.rootStore.floors.fetchFloorAssets({
          floorId,
          buildingId: this.buildingId,
        });
      }),

      clearFloor: action('clearFloor', () => {
        this.floorId = null;
      }),

      setPointAId: action('setPointA', pointId => {
        if (pointId) {
          this.pointAId = parseInt(pointId, 10);
          this.rootStore.points.setPointA(this.pointAId);
        } else {
          this.clearPointAId();
        }
      }),

      setPointBId: action('setPointB', pointId => {
        if (pointId) {
          this.pointBId = parseInt(pointId, 10);
          this.rootStore.points.setPointB(this.pointBId);
        } else {
          this.clearPointBId();
        }
      }),

      // Sets whichever point isn't set. Defaults to pointBId.
      setPointId: action('setPointId', (pointId, targetPoint = '') => {
        if (!targetPoint) {
          if (!this.pointBId) {
            this.setPointBId(pointId);
          } else {
            this.setPointAId(pointId);
          }
        } else {
          if (targetPoint === 'pointA') {
            this.setPointAId(pointId);
          } else {
            this.setPointBId(pointId);
          }
        }

        this.clearSelectedPoint();

        // If both points are set, fetch the routes and directions
        if (this.pointAId && this.pointBId) {
          this.rootStore.routes.fetchDirections({
            pointAId: this.pointAId,
            pointBId: this.pointBId,
            isAccessible: this.isAccessible
          });

          // Clear the query string after both points are set.
          // otherwise the points might not show on the map.
          this.clearQueryStr();
          const pointA = this.rootStore.points.getPointById(this.pointAId);
          this.setFloor(pointA.floorId);
        }
      }),

      clearPointAId: action('clearPointAId', () => {
        this.pointAId = null;
        this.rootStore.points.clearPointA();
      }),

      clearPointBId: action('clearPointBId', () => {
        this.pointBId = null;
        this.rootStore.points.clearPointB();
      }),

      clearDirectionPoints: action('clearDirectionPoints', () => {
        this.clearPointAId();
        this.clearPointBId();
      }),

      switchPoints: action('switchPoints', pointId => {
        const pointAId = this.pointAId;
        this.setPointAId(this.pointBId);
        this.setPointBId(pointAId);

        if (this.pointAId && this.pointBId) {
          this.rootStore.routes.fetchDirections({
            pointAId: this.pointAId,
            pointBId: this.pointBId,
            isAccessible: this.isAccessible
          });
          const pointA = this.rootStore.points.getPointById(this.pointAId);
          this.setFloor(pointA.floorId);
        }
      }),

      setCenterPoint: action('setCenterPoint', point => {
        this.centerPoint = `${point.lat},${point.lng}`;
      }),

      clearCenterPoint: action('clearCenterPoint', () => {
        this.centerPoint = null;
      }),

      setSelectedPoint: action('setSelectedPoint', point => {
        const { id, buildingId, floorId } = point;

        this.selectedPointId = id;
        this.setBuilding(buildingId);
        this.setFloor(floorId);

        if (this.centerPoint) {
          this.clearCenterPoint();
        }
      }),

      clearSelectedPoint: action('clearSelectedPoint', () => {
        this.selectedPointId = null;
      }),

      setQuery: action('setQuery', query => {
        this.queryStr = query;
      }),

      clearQueryStr: action('clearQueryStr', () => (this.queryStr = '')),

      setAccessiblity: action(
        'setAccessiblity',
        bool => {
          this.isAccessible = !!bool;
          if (this.pointAId && this.pointBId) {
            this.rootStore.routes.fetchDirections({
              pointAId: this.pointAId,
              pointBId: this.pointBId,
              isAccessible: this.isAccessible
            });
          }
        }
      ),
    });

    // If the campus id isn't in the URL, then add it if it
    // exists in local storage.
    if (!rootStore.history.location.query.campus) {
      const storageCampusId = parseInt(getItem('campusId', null), 10);
      // If it exists in local storage, then add it to the URL.
      if (!isNaN(storageCampusId)) {
        this.replaceInUrl('campus', storageCampusId);
      }
    }

    /************************************************
     * Observers
     *************************************************/
    observe(this, this.onUiChange.bind(this));
  }

  onUiChange(changes) {
    if (changes.type !== 'update') {
      return;
    }

    const value = changes.newValue;
    switch (changes.name) {
      case 'campusId':
        this.replaceInUrl('campus', value);
        break;
      case 'buildingId':
        this.replaceInUrl('building', value);
        break;
      case 'floorId':
        this.replaceInUrl('floor', value);
        break;
      case 'selectedPointId':
        this.replaceInUrl('selectedPoint', value);
        break;
      case 'pointAId':
        this.replaceInUrl('startingPoint', value);
        break;
      case 'pointBId':
        this.replaceInUrl('destinationPoint', value);
        break;
      case 'queryStr':
        this.replaceInUrl('query', value);
        break;
      case 'mode':
        this.replaceInUrl('mode', value);
        break;
      case 'isAccessible':
        this.replaceInUrl('accessible', value);
        break;
      case 'centerPoint':
        this.replaceInUrl('centerPoint', value);
        break;
      default:
        break;
    }
  }

  getFromQuery(name) {
    const query = this.rootStore.history.location.query;
    return query[name];
  }

  getIntFromQuery(name) {
    const value = parseInt(this.getFromQuery(name), 10);
    return isNaN(value) ? null : value;
  }

  replaceInUrl(name, value) {
    const { history } = this.rootStore;

    const query = {
      ...history.location.query,
    };

    if (value) {
      query[name] = value;
    } else {
      delete query[name];
    }

    // Update the campus id in the URL.
    history.replace({
      ...history.location,
      query,
    });
  }
}

export default UiStore;
